<template>
  <div class="rn-contact-area rn-section-gap bg_color--1" id="contact">
    <div class="contact-form-notin">
      <v-container>
        <v-row>
          <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
            <div class="text-left mb--30 titulo-form-notin">
              <h2 class="heading-title">{{ props.contactForm.Titulo.Texto }}</h2>
              <p class="description">
                {{ props.contactForm.Descripcion.Texto }}
              </p>
            </div>
            <div class="form-wrapper fields-form-notin">
              <form v-if="!enviado">
                <fieldset name="Formulario Contacto" style="border:0">
                  <div>
                    <div class="input-error">
                      <div v-for="keyError, errorindex in Object.keys(errors)" v-bind:key="errorindex">{{
                errors[keyError]
              }}
                      </div>
                    </div>
                    <label for="name" class="description">
                      {{ $t('nombre') }} *</label>
                    <input id="name" name="name" v-if="props.contactForm.Fields.Name" type="text" v-model="form.name"
                      :placeholder="$t('nombre')" />
                    <span class="input-error">{{ errors.name }}</span>


                    <label for="email" class="description">
                      Email *</label>
                    <input id="email" name="email" type="text" v-model="form.email" placeholder="Email"
                      v-if="props.contactForm.Fields.Email" />
                    <span class="input-error">{{ errors.email }}</span>



                    <label for="phone">
                      {{ $t('telefono') }} *</label>
                    <input id="phone" name="phone" v-if="props.contactForm.Fields.Phone" type="text" rules="required"
                      v-model="form.phone" :placeholder="$t('telefono')" />
                    <span class="input-error">{{ errors.phone }}</span>



                    <label for="subject">
                      {{ $t('asunto') }} *</label>
                    <input id="subject" name="subject" v-if="props.contactForm.Fields.Subject" type="text"
                      v-model="form.subject" :placeholder="$t('asunto')" />
                    <span class="input-error">{{ errors.subject }}</span>



                    <label for="message">
                      {{ $t('mensaje') }} *</label>
                    <textarea id="message" name="message" v-if="props.contactForm.Fields.Message" v-model="form.message"
                      :placeholder="$t('mensaje')"></textarea>
                    <span class="input-error">{{ errors.message }}</span>


                    <div class="content check-pd">
                      <input type="checkbox" v-model="checkboxpd" name="pd" id="pd" class="m-0"/> 
                      <label for="pd">He leído y acepto la <NuxtLink :to="'/protecciondatos'">Política de protección de datos</NuxtLink></label>
                    </div>
                    <div class="input-error">{{ errors.pd }}</div>
                    <!-- <v-checkbox v-model="aceptoPD"> He leido y acepto la <NuxtLink :to="'/protecciondatos'">Política de protección de datos</NuxtLink></v-checkbox>
                   -->
                  </div>

                </fieldset>

              </form>
              <v-btn class="rn-button-style-notin-1 button-form-notin" type="submit" @click="handleSubmit()"
                :color="props.contactForm.Button.css.BackgroundColor" :loading="sending" v-if="!enviado"
                :aria-label="props.contactForm.Button.Texto" :title="props.contactForm.Button.Texto">
                {{ props.contactForm.Button.Texto }}
              </v-btn>
              <p v-if="enviado" class="inpur-success">{{ $t('emailEnviado') }}</p>
              <p v-if="enviado === false" class="input-error">{{ $t('emailError') }}</p>
              <p v-if="lockEmail" class="input-error">{{ $t('lockEmail') }}</p>
            </div>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="2">
            <div class="thumbnail mb_md--40 mb_sm--40">
              <img
                :src="props.contactForm.Imagen ? imagenIDtoSRC(props.contactForm.Imagen) : '~@/assets/images/about/about-6.jpg'"
                alt="Fotografia asociada al formulario de contacto" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script setup lang="ts">
import nuxtStorage from 'nuxt-storage';
import { useStore } from '../store/index'
const store = useStore()
const { NOTARIA } = storeToRefs(store)
import { useUtils } from '#imports';
const { imagenIDtoSRC, $t } = useUtils()
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3';
const { vueApp } = useNuxtApp();

const props = defineProps({
  contactForm: {
    type: Object,
    required: true
  }
})
const actionName = 'email'
const sending = ref(false)
const enviado = ref(null)
const lockEmail = ref(null)
const checkboxpd = ref(null)
const form = ref({
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
  recaptcha: {
    id_credential: store.NOTARIA.Personalizar.RecaptchaGroup,
    token: "",
    action: actionName
  }
})
const errors = ref([])
async function created() {
  var lastEnviado = typeof window !== 'undefined' ? nuxtStorage.localStorage.getData('enviado'):null
  if (lastEnviado != null) {
    var diferencia = (new Date()).getTime() - lastEnviado
    if (diferencia > 60 * 60 * 1000) {//10minutos
      enviado.value = false
      lockEmail.value = false
    } else {
      enviado.value = true
      lockEmail.value = true
    }
  }
}
//watch(()=>props.contactForm, (n) => form.value = n, { deep: true })


//Init recaptcha
vueApp.use(VueReCaptcha, {
  siteKey: store.recaptchaSiteKey,
  loaderOptions: {
    autoHideBadge: false,
  },
});
async function mounted() {
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
  await recaptchaLoaded();
  form.value.recaptcha.token = await executeRecaptcha(actionName);
}
onMounted(() => {
  mounted()
})
async function handleSubmit(e) {
  if (e) e.preventDefault();
  if (form.value) {//&& form.token
    sending.value = true;
    debugger
    if (checkForm()) {
      var result = await sendMail(form.value);
      enviado.value = result == "" ? true : false;
      if (enviado.value) {
        nuxtStorage.localStorage.setData('enviado', (new Date()).getTime());
        // var IdEmail
        // if(!NOTARIA.Emails){
        //   NOTARIA.Emails = []
        //   IdEmail=0
        // }else{
        //   IdEmail
        // }
        // var emailModel={

        // }
        // NOTARIA.Emails.push(emailModel)

        resetForm()
      } else {
        await mounted()
      }
    }
    debugger
    sending.value = false;
    if (e) e.preventDefault()

  }

}
function resetForm() {
  form.value = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    recaptcha: {
      id_credential: store.NOTARIA.Personalizar.RecaptchaGroup,
      token: "",
      action: actionName
    }

  }
}
function checkForm() {
  errors.value = { name: "", email: "", phone: "", subject: "", message: "", pd: "" }
  var valid = true
  if (props.contactForm.Fields.Name && props.contactForm.Fields.ForceName && !form.value.name) {
    errors.value.name = "El nombre es obligatorio."
    valid = false
  }
  if (props.contactForm.Fields.Email && props.contactForm.Fields.ForceEmail) {
    if (!form.value.email || form.value.email.length < 5) {
      errors.value.email = 'El email es obligatorio.'
      valid = false
    }
    if (!validEmail(form.value.email)) {
      errors.value.email = 'El email debe ser válido.'
      valid = false
    }

  }
  if (props.contactForm.Fields.Phone && props.contactForm.Fields.ForcePhone && !form.value.phone) {
    errors.value.phone = 'El telefono es obligatorio.'
    valid = false
  }
  if (props.contactForm.Fields.Subject && props.contactForm.Fields.ForceSubject && !form.value.subject) {
    errors.value.subject = 'El asunto es obligatorio'
    valid = false
  }
  if (props.contactForm.Fields.Message && props.contactForm.Fields.ForceMessage && !form.value.message) {
    errors.value.message = 'El mensaje es obligatorio.'
    valid = false
  }
  if (!checkboxpd.value) {
    errors.value.pd = 'Por favor, lea y acepte la política de protección de datos.'
    valid = false
  }


  return valid;


}
function validEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

async function sendMail(formData: any) {
  var cuerpo = "Mensaje enviado desde la web: " + location.origin + "<br><br>";

  if (formData.name != "")
    cuerpo = cuerpo + "Remitente: " + formData.name + "<br><br>";
  if (formData.correo != "")
    cuerpo = cuerpo + 'Correo de contacto: <a href="mailto:' + formData.email + '">' + formData.email + '</a>' + "<br><br>";
  if (formData.phone != "")
    cuerpo = cuerpo + "Teléfono: " + formData.phone + "<br><br>";
  if (formData.asunto != "")
    cuerpo = cuerpo + "Asunto: " + formData.subject + "<br><br>";
  if (formData.message)
    cuerpo = cuerpo + "Mensaje: " + formData.message + "<br><br>";



  var model = {
    "perfil": "info_recaptcha_web_notaria",
    //"perfil": "info_recaptcha_web_notaria",
    "cuerpo": cuerpo,
    "is_cuerpo_html": true,
    "asunto": "Contacto WEB " + store.NOTARIA.Nombre,
    "destinatarios": [{ "nombre": store.NOTARIA.Nombre, "direccion": store.NOTARIA.Contacto.Email }],//
    "destinatarios_cco": [{ "nombre": "webNotin", "direccion": "web@notin.net" }],
    "recaptcha": formData.recaptcha,
    "data":{
        id_notaria: store.NOTARIA.id,
        Fecha: new Date().getTime().toString(),
        Name: formData.name,
        Email: formData.email,
        Phone: formData.phone,
        Subject: formData.subject,
        Message: formData.message
    }
    //"destinatarios": [{"nombre": "Web Notin", "direccion": "modelos@notin.net"}],
    //"destinatarios_cco": [{"nombre": "Web Notin", "direccion": "modelos@notin.net"}],
    //"destinatarios": [{"nombre": store.NOTARIA.Nombre, "direccion": 'modelos@notin.net'}],
    // "adjuntos": [
    //     {"nombre": "hola.txt", "contenido": "aG9sYSBhIHRvZG9zIGFtaWdvcyBkZWwgbXVuZG8gbXVuZGlhbA=="}
    // ]
  }
  var mail_service = ""
  // if (process.env.NODE_ENV == "development") {
  //   mail_service = 'http://localhost:3500/api/email'
  // }
  // else {
  //   mail_service = 'https://mailing.notin.es/api/email';
  // }
  mail_service = 'https://mailing.notin.es/api/email';
  try {
    let res: any = await $fetch(mail_service, { method: "POST", body: model, headers: { 'Content-Type': 'application/json' } })
    // if (res == "") {
    //   //enviamos copia de email a 
    //   model.destinatarios = [{ "nombre": "webNotin", "direccion": "web@notin.net" }],//modelos@notin.net
    //     res = await $fetch(mail_service,{method:"post", body:model, headers: { 'Content-Type': 'application/json' } })
    // }
    return res;
  } catch (e) {
    //console.log(e)
  }

}

const estiloTituloColor = ref(props.contactForm.Titulo.css.Color)
const estiloTituloBackgroundColor = ref(props.contactForm.Titulo.css.BackgroundColor)
const estiloTituloFont = ref(props.contactForm.Titulo.css.Font)
const estiloTituloSize = ref(props.contactForm.Titulo.css.Size)
const estiloTituloWeight = ref(props.contactForm.Titulo.css.Weight)
const estiloTituloAlign = ref(props.contactForm.Titulo.css.Align)

const estiloDescripcionColor = ref(props.contactForm.Descripcion.css.Color)
const estiloDescripcionBackgroundColor = ref(props.contactForm.Descripcion.css.BackgroundColor)
const estiloDescripcionFont = ref(props.contactForm.Descripcion.css.Font)
const estiloDescripcionSize = ref(props.contactForm.Descripcion.css.Size)
const estiloDescripcionWeight = ref(props.contactForm.Descripcion.css.Weight)
const estiloDescripcionAlign = ref(props.contactForm.Descripcion.css.Align)

const estiloButtonColor = ref(props.contactForm.Button.css.Color)
const estiloButtonBackgroundColor = ref(props.contactForm.Button.css.BackgroundColor)
const estiloButtonFont = ref(props.contactForm.Button.css.Font)
const estiloButtonSize = ref(props.contactForm.Button.css.Size)
const estiloButtonWeight = ref(props.contactForm.Button.css.Weight)
const estiloButtonAlign = ref(props.contactForm.Button.css.Align)

created()
</script>
<style lang="scss">
.titulo-form-notin h2 {
  color: v-bind(estiloTituloColor)!important;
  //background-color:v-bind(estiloTituloBackgroundColor) ;
  text-shadow: 4px 2px 6px v-bind(estiloTituloBackgroundColor)!important;
  font-family: v-bind(estiloTituloFont)!important;
  font-size: v-bind(estiloTituloSize) !important;
  font-weight: v-bind(estiloTituloWeight) !important;
  text-align: v-bind(estiloTituloAlign)!important;
}

.titulo-form-notin p {
  color: v-bind(estiloDescripcionColor) !important;
  //background-color:v-bind(estiloDescripcionBackgroundColor) ;
  text-shadow: 4px 2px 6px v-bind(estiloDescripcionBackgroundColor)!important;
  font-family: v-bind(estiloDescripcionFont)!important;
  font-size:v-bind(estiloDescripcionSize)!important;
  font-weight: v-bind(estiloDescripcionWeight)!important;
  text-align: v-bind(estiloDescripcionAlign)!important;
}

.button-form-notin {
  color: v-bind(estiloButtonColor) !important;
  background-color: v-bind(estiloButtonBackgroundColor) !important;
  font-family: v-bind(estiloButtonFont);
  font-size:v-bind(estiloButtonSize) ;
  font-weight: v-bind(estiloButtonWeight);
  text-align:v-bind(estiloButtonAlign);
  border: 2px solidv-bind(estiloButtonBackgroundColor);
}

.fields-form-notin input:focus,
.fields-form-notin textarea:focus {
  border-color: v-bind(estiloButtonBackgroundColor);
}

.fields-form-notin {
  color: v-bind(estiloButtonColor);
  //background-color:v-bind(estiloTitulo.BackgroundColor) ;
  //text-shadow: 4px 2px 6px v-bind(estiloButtonBackgroundColor) ;
  font-family: v-bind(estiloButtonFont);
  //font-size:v-bind(estiloSubtituloSize) ;
  font-weight: v-bind(estiloButtonWeight);
  //text-align:v-bind(estiloSubtituloAlign);
}

.fields-form-notin label {
  color: v-bind(estiloButtonBackgroundColor);
  //background-color:v-bind(estiloTitulo.BackgroundColor) ;            
  font-family: v-bind(estiloButtonFont);
  //font-size: 15px;
  //font-size:v-bind(estiloSubtituloSize) ;
  font-weight: v-bind(estiloButtonWeight);
  //text-align:v-bind(estiloSubtituloAlign);
}

.check-pd {
  input {
    width: 20px;
    height: 20px;
  }
  label {
    //color: #283593FF;
    color: v-bind(estiloTituloColor);
    font-size: 20px;
    display: inline !important;
    margin-left: 4px;
  }

  a {
    color: v-bind(estiloTituloColor);
    text-decoration: underline !important;
  }
}
</style>